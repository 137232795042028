@font-face {
  font-family: "Axiforma-Private";
  font-style: normal;
  font-weight: 400;
  src: url("../public/fonts/axiforma/Axiforma-Regular.ttf");
}

@font-face {
  font-family: "Axiforma-Private";
  font-style: normal;
  font-weight: 500;
  src: url("../public/fonts/axiforma/Axiforma-Medium.ttf");
}

@font-face {
  font-family: "Axiforma-Private";
  font-style: normal;
  font-weight: 600;
  src: url("../public/fonts/axiforma/Axiforma-SemiBold.ttf");
}

@font-face {
  font-family: "Axiforma-Private";
  font-style: normal;
  font-weight: 700;
  src: url("../public/fonts/axiforma/Axiforma-Bold.ttf");
}

html {
  width: 100%;
}

body {
  background: #1a1c1e;
  margin: 0;
  overflow-x: hidden;
  height: 100vh;
}

/* Scrollbar */

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(137, 139, 142);
  border-radius: 100px;
}

.build-out {
  /* transform: scaleY(0);
  transform-origin: 0 0; */
  height: 0%;
}

.build-in {
  /* transform: scaleY(1); */
  height: 100%;
}

#sticker{
  position: fixed;
  bottom: 0;
  right: 0;
  transform: scale(0.8);
  z-index: 99;
}

@media (max-width: 700px) {
 #sticker{
  bottom: -48px;
  right: -48px;
  transform: scale(0.5);
 }
}

.fade-in {
  visibility: visible !important;
  opacity: 1 !important;
  transform: scale(1) !important;
}

.fade-out {
  visibility: visible !important;
  opacity: 0 !important;
  transform: scale(0) !important;
}

.shrink-out {
  visibility: visible !important;
  opacity: 0 !important;
  height: 0;
}

.fade-up {
  opacity: 0 !important;
  transform: translateY(80px) !important;
  transition: opacity 0.4s ease, transform 0.4s ease !important;
}

.fade-up.fade-up-animate {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

[data-sal|="fade-up"] {
  opacity: 0;
  transform: translateY(40px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

[data-sal|="fade-up"].sal-animate {
  opacity: 1;
  transform: translateY(0);
}

@media (min-aspect-ratio: 16/9) {
  #videoBG {
    width: 100%;
    height: auto;
  }
}

@media (max-aspect-ratio: 16/9) {
  #videoBG {
    width: auto;
    height: 100%;
  }
}

input[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
